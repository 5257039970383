import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import styled from 'styled-components';
const Image = props => {
  return (
    <BackgroundImage
      Tag="div"
      title={props.title}
      className={props.className}
      fluid={props.data.childImageSharp.fluid}
    ></BackgroundImage>
  );
};
const StyledBackgroundSection = styled(Image)`
  width: 100%;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
`;
export default StyledBackgroundSection;

import React from 'react';
import { Link } from 'gatsby';
import AnimateImage from '../components/Animation';
import { Animation } from 'react-genie';
import { useStaticQuery, graphql } from 'gatsby';
import Img from './image';
import LazyLoad from 'react-lazy-load';

//! Images
import Faith from '../img/macy@3x.png';
import AxisAha from '../img/triniti-new-home-silver@4x.svg';
import Devices from '../img/devices@1x.svg';

const LandingBusinessBenefits = props => {
  const data = useStaticQuery(graphql`
    query {
      AutomaticVariants: file(
        relativePath: { eq: "1.-Automatic-variants.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      SpellChecker: file(relativePath: { eq: "2.-Spell-checkers.png" }) {
        childImageSharp {
          fluid(maxWidth: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Dissambiguation: file(relativePath: { eq: "3.-Dissambiguation.png" }) {
        childImageSharp {
          fluid(maxWidth: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      FalloverToAgent: file(relativePath: { eq: "4.-Fallover-to-agents.png" }) {
        childImageSharp {
          fluid(maxWidth: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Analytics: file(relativePath: { eq: "5.-Analytics.png" }) {
        childImageSharp {
          fluid(maxWidth: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Scalable: file(relativePath: { eq: "6.-Scalable-secure.png" }) {
        childImageSharp {
          fluid(maxWidth: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Amazon: file(relativePath: { eq: "logo-51-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <div className="landing-business-benefits">
      <div className="business-benefit-wrapper-outer">
        <div className="business-benefit-wrapper">
          <div className="business-benefit ">
            <LazyLoad className="image" offsetVertical={500}>
              <AnimateImage
                alt="Faith"
                src={Faith}
                css={'image'}
                animation={Animation.SlideInLeft}
              />
            </LazyLoad>
            <div className="business-benefit-text">
              <h2 className="benefit-title">Automate your customer support</h2>
              <p className="benefit-title-subtext">
                Transform your customer service, reduce cost, happier customers
              </p>
              <h4>Reduce cost by 100%</h4>
              <p>
                CognitiveQnA handles all frequently asked questions, thus
                enabling you to scale with your customer support team
              </p>
              <h4>Increase customer satisfaction</h4>
              <p>
                CognitiveQnA responds instantly in realtime, avoiding the
                frustration wait times, leading to increased customer
                satisfaction
              </p>
              <h4>Engage customers, increase revenue</h4>
              <p>
                CognitiveQnA can guide customers to get more information on
                related products or services and even cross sell new products
                and accept leads leading to additional revenue lines
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="business-benefit-wrapper-outer">
        <div className="business-benefit-wrapper">
          <div className="business-benefit image-right">
            <LazyLoad className="image-column-36" offsetVertical={500}>
              <AnimateImage
                alt="Axis Aha"
                src={AxisAha}
                css={'image'}
                animation={Animation.SlideInRight}
              />
            </LazyLoad>
            <div className="business-benefit-text">
              <h2 className="benefit-title">
                Go live in minutes, achieve high accuracy
              </h2>
              <p className="benefit-title-subtext">
                No manual annotation, cutting edge deep learning algorithms
              </p>
              <h4>Minimal variants required</h4>
              <p>
                Minimal or none* additional training data required, CognitiveQnA
                works with high accuracy on standard FAQ's with a single
                question answer pair.
              </p>
              <h4>Automatic data annotation</h4>
              <p>
                No manual annotation of data CognitiveQnA uses cutting edge
                algorithms to automatically annotate data which can be
                fine-tuned.
              </p>
              <h4>Deep learning domain models</h4>
              <p>
                Pre bundled domain models boost the paraphrasing algorithms
                leading to greater breadth of coverage and increasing accuracy.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="business-benefit-wrapper-outer">
        <div className="business-benefit-wrapper">
          <div className="business-benefit ">
            <LazyLoad className="image" offsetVertical={500}>
              <AnimateImage
                alt="Devices"
                src={Devices}
                css={'image'}
                animation={Animation.FadeIn}
                style={{ animationDuration: '4s' }}
              />
            </LazyLoad>
            <div className="business-benefit-text">
              <h2 className="benefit-title">
                All you need for next gen virtual assistant
              </h2>
              <p className="benefit-title-subtext">
                Natural conversations, design conversation journeys with
                OmniChannel capability
              </p>
              <h4>Conversation context</h4>
              <p>
                Conversational memory retention capability which enables follow
                on human like contextual conversations.
              </p>
              <h4>Conversation designer</h4>
              <p>
                Intuitive feature rich drag and drop conversational workflow
                designer to orchestrate simple and complex journeys and with
                ease.
              </p>
              <h4>OmniChannel capability</h4>
              <p>
                Out of the box support for social messaging channels, web and
                mobile via easy to bundle SDK’s
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="landing-matrix">
        <div className="landing-matrix-feature">
          <div className="image">
            <Img data={data.AutomaticVariants} />
          </div>
          <h3>Automatic variants</h3>
          <p>
            Generate synthetic variants automatically to further improve
            accuracy using CognitiveQnA's powerful paraphrasing algorithms.
          </p>
        </div>
        <div className="landing-matrix-feature">
          <div className="image">
            <Img data={data.SpellChecker} />
          </div>
          <h3>Spell checkers</h3>
          <p>
            Bidirectional contextual spell checking algorithms which correct
            user input leading to higher accuracy.
          </p>
        </div>
        <div className="landing-matrix-feature">
          <div className="image">
            <Img data={data.Dissambiguation} />
          </div>
          <h3>Disambiguation</h3>
          <p>
            Disambiguation capability for ambiguous queries with guided user
            flows for clarification before responding.
          </p>
        </div>
        <div className="landing-matrix-feature">
          <div className="image">
            <Img data={data.Dissambiguation} />
          </div>
          <h3>Failover to agents</h3>
          <p>
            Graceful failover to human agents in case for unclassified queries
            on both text and voice (IVR).
          </p>
        </div>
        <div className="landing-matrix-feature">
          <div className="image">
            <Img data={data.Analytics} />
          </div>
          <h3>Analytics</h3>
          <p>
            Measure virtual bot metrics by users, sessions, conversations across
            channels.
          </p>
        </div>
        <div className="landing-matrix-feature">
          <div className="image">
            <Img data={data.Scalable} />
          </div>
          <h3>Scalable &amp; secure</h3>
          <p>
            Scalable, on demand, autoscale infrastructure hosted on AWS platform
            with banking grade security &amp; data privacy.
          </p>
        </div>
        <Link className="landing-matrix-see-all" to="/features/">
          See all features<span className="all-arrow"></span>
        </Link>
      </div>
    </div>
  );
};

export default LandingBusinessBenefits;

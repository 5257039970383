import React from 'react';
import { Reveal } from 'react-genie';

const AnimateImage = props => {
  return (
    <Reveal animation={props.animation + ` ${props.css}`} style={props.style}>
      <img alt={props.alt} src={props.src} style={props.imgStyle} />
    </Reveal>
  );
};

export default AnimateImage;

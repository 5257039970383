import React, { useState } from 'react';
import AnimateImage from '../components/Animation';
import { Animation } from 'react-genie';
//! Images
import CreateVirtualBot from '../img/How-does-it-work-01@3x-1.png';
import CreateVirtualBotZoomed from '../img/How-does-it-work-01-zoom@3x-1.png';
import QnA from '../img/How-does-it-work-02@3x.png';
import QnAZoomed from '../img/How-does-it-work-02-zoom@3x.png';
import TrainModel from '../img/How-does-it-work-03@3x.png';
import TrainModelZoomed from '../img/How-does-it-work-03-zoom@3x.png';
import EnableChannel from '../img/How-does-it-work-04@3x-1.png';
import EnableChannelZoomed from '../img/How-does-it-work-04-zoom@3x.png';
import GoLive from '../img/How-does-it-work-05@3x.png';
import GoLiveZoomed from '../img/How-does-it-work-05-zoom@3x.png';

const LandingExplanation = () => {
  const tabs = [
    'Create virtual bot',
    'Import QnA',
    'Train Model',
    'Enable channels',
    'Go live'
  ];

  const [index, setIndex] = useState(1);

  const toggleTabs = index => {
    setIndex(index);
  };

  return (
    <div id="how-does-it-work" className="landing-explanation">
      <div className="landing-explanation-inner">
        <h2>How does it work?</h2>
        <p>Intuitive tools help you configure your virtual bot in 10 mins</p>
        <div className="Panel">
          <nav>
            <ul className="Tabs">
              {tabs.map((tab, idx) => {
                return (
                  <React.Fragment key={idx + 1}>
                    {idx !== 0 && <span className="dot"></span>}
                    <li
                      data-tab={idx + 1}
                      key={idx + 1}
                      className={`Tabs__tab Tab ${
                        index === idx + 1 ? 'active' : ''
                      }`}
                      onClick={() => toggleTabs(idx + 1)}
                    >
                      <span className="tab-number">{idx + 1}</span>
                      <span className="tab-text">{tab}</span>
                    </li>
                  </React.Fragment>
                );
              })}
              <li
                className="Tabs__presentation-slider"
                role="presentation"
              ></li>
            </ul>
          </nav>
          <div className="Panel_body">
            <div
              data-tab="1"
              className={`Panel_item ${index === 1 ? 'active' : 'inactive'}`}
            >
              <div className="Panel_summary">
                <h3>Create virtual bot</h3>
                <p>
                  Build your own Conversation AI workspace for your virtual bot
                </p>
              </div>
              <AnimateImage
                alt="Create virtual bot"
                src={CreateVirtualBot}
                css={'Panel_image'}
                animation={Animation.FadeIn}
                style={{ animationDuration: '2s' }}
              />
              <div className="Panel_zoom">
                <img
                  alt="Create virtual bot"
                  src={CreateVirtualBotZoomed}
                  className={`${index === 1 ? '' : 'zoomed'}`}
                />
              </div>
            </div>
          </div>
          <div
            data-tab="2"
            className={`Panel_item ${index === 2 ? 'active' : 'inactive'}`}
          >
            <div className="Panel_summary">
              <h3>Import QnA</h3>
              <p>Simply import a FAQ URL or weblink</p>
            </div>
            <AnimateImage
              alt="Import QnA"
              src={QnA}
              css={'Panel_image'}
              animation={Animation.FadeIn}
              style={{ animationDuration: '2s' }}
            />
            <div className="Panel_zoom">
              <img
                alt="Import QnA"
                src={QnAZoomed}
                className={`${index === 2 ? '' : 'zoomed'}`}
              />
            </div>
          </div>
          <div
            data-tab="3"
            className={`Panel_item ${index === 3 ? 'active' : 'inactive'}`}
          >
            <div className="Panel_summary">
              <h3>Train Model</h3>
              <p>Simply train the data models and test your bot</p>
            </div>
            <AnimateImage
              alt="Train Model"
              src={TrainModel}
              css={'Panel_image'}
              animation={Animation.FadeIn}
              style={{ animationDuration: '2s' }}
            />
            <div className="Panel_zoom">
              <img
                alt="Train Model"
                src={TrainModelZoomed}
                className={`${index === 3 ? '' : 'zoomed'}`}
              />
            </div>
          </div>
          <div
            data-tab="4"
            className={`Panel_item ${index === 4 ? 'active' : 'inactive'}`}
          >
            <div className="Panel_summary">
              <h3>Enable channels</h3>
              <p>Select a channel which you want your bot to be used in</p>
            </div>
            <AnimateImage
              alt="Enable channels"
              src={EnableChannel}
              css={'Panel_image'}
              animation={Animation.FadeIn}
              style={{ animationDuration: '2s' }}
            />
            <div className="Panel_zoom">
              <img
                alt="Enable channels"
                src={EnableChannelZoomed}
                className={`${index === 4 ? '' : 'zoomed'}`}
              />
            </div>
          </div>
          <div
            data-tab="5"
            className={`Panel_item ${index === 5 ? 'active' : 'inactive'}`}
          >
            <div className="Panel_summary">
              <h3>Go live</h3>
              <p>Launch your bot to your users</p>
            </div>
            <AnimateImage
              alt="Go live"
              src={GoLive}
              css={'Panel_image'}
              animation={Animation.FadeIn}
              style={{ animationDuration: '2s' }}
            />
            <div className="Panel_zoom">
              <img
                alt="Go live"
                src={GoLiveZoomed}
                className={`${index === 5 ? '' : 'zoomed'}`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingExplanation;

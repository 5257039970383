import React from 'react';
import LazyLoad from 'react-lazy-load';

//! Images
import CustomerSupport from '../img/Triniti-home-icon-01-1.svg';
import CustomerEngagement from '../img/Triniti-home-icon-03-2.svg';
import AgentCapacity from '../img/Triniti-home-icon-02-1.svg';

const LandingIntroductionBenefitsWrapper = props => {
  return (
    <div className="landing-introduction-benefits-wrapper">
      <div className="landing-introduction-benefits">
        <div>
          <LazyLoad offsetVertical={500}>
            <img alt="Customer Support" src={CustomerSupport} />
          </LazyLoad>
          <p>
            Transform
            <br />
            your customer support
            <br />
            in minutes
          </p>
        </div>
        <div>
          <LazyLoad offsetVertical={500}>
            <img alt="Customer Engagement" src={CustomerEngagement} />
          </LazyLoad>
          <p>
            Enhance
            <br />
            customer engagement
            <br />
            and satisfaction
          </p>
        </div>
        <div>
          <LazyLoad offsetVertical={500}>
            <img alt="Agent Capacity" src={AgentCapacity} />
          </LazyLoad>
          <p>
            Scale
            <br />
            agent capacity and
            <br />
            lower cost
          </p>
        </div>
      </div>
    </div>
  );
};

export default LandingIntroductionBenefitsWrapper;

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from './image';

const LandingIntroduction = props => {
  const data = useStaticQuery(graphql`
    query {
      facebookLine: file(relativePath: { eq: "chart13@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      facebookIcon: file(relativePath: { eq: "chart10@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      chart: file(relativePath: { eq: "chart1.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      whatsAppLine: file(relativePath: { eq: "chart14@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      whatsAppIcon: file(relativePath: { eq: "chart11@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      microphoneIcon: file(relativePath: { eq: "chart12@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      microphoneLine: file(relativePath: { eq: "chart15@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      trinitiIcon: file(relativePath: { eq: "chart3.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      deepLearningIcon: file(relativePath: { eq: "chart4.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cognitiveIcon: file(relativePath: { eq: "chart5.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      knowledgeIcon: file(relativePath: { eq: "chart7.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      knowledgeIconLine: file(relativePath: { eq: "chart8.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      faqIcon: file(relativePath: { eq: "chart6.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      faqIconLine: file(relativePath: { eq: "chart9.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      customerSupport: file(relativePath: { eq: "chart16@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      customerSupportLine: file(relativePath: { eq: "chart17@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      chartMobile: file(relativePath: { eq: "triniti-diagram@3x-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <div className="landing-introduction">
      <div className="landing-introduction-inner">
        <h2>
          The fastest way to automate your
          <br />
          customer support
        </h2>
        <p>
          CognitiveQnA is a AI powered customer service automation platform
          which handles customer queries without any human intervention.
          CognitiveQnA applies cutting edge deep learning algorithms to
          understand and comprehend customer queries in order to find the best
          answer to the question, thus enabling higher customer satisfaction,
          lower customer service costs and transforming customer service into a
          competitive advantage.
        </p>
        <div className="chart-mobile">
          <Img data={data.chartMobile} />
        </div>
        <div className="chart">
          <Img data={data.chart} className="chart-image" />
          <h3 className="chart-title chart-text">TRINITI.AI</h3>
          <Img
            data={data.facebookIcon}
            className="facebook-icon outer-left-icon"
          />
          <Img data={data.facebookLine} className="facebook-line left-line" />
          <Img
            data={data.whatsAppIcon}
            className="whatsapp-icon outer-left-icon"
          />
          <Img data={data.whatsAppLine} className="whatsapp-line left-line" />
          <Img
            data={data.microphoneIcon}
            className="microphone-icon outer-left-icon"
          />
          <Img
            data={data.microphoneLine}
            className="microphone-line left-line"
          />
          <Img data={data.trinitiIcon} className="triniti-icon top-row-icon" />
          <Img
            data={data.deepLearningIcon}
            className="deep-learning-icon top-row-icon big-inner-icon"
          />
          <Img
            data={data.cognitiveIcon}
            className="cognitive-icon top-row-icon big-inner-icon"
          />
          <Img
            data={data.knowledgeIcon}
            className="knowledge-icon bottom-row-icon"
          />
          <Img data={data.knowledgeIconLine} className="knowledge-line" />
          <Img
            data={data.faqIcon}
            className="faq-icon bottom-row-icon big-inner-icon"
          />
          <Img data={data.faqIconLine} className="faq-line" />
          <Img data={data.customerSupport} className="customer-support-icon" />
          <p className="customer-support-text chart-text">
            CUSTOMER
            <br />
            SUPPORT
          </p>
          <Img
            data={data.customerSupportLine}
            className="customer-support-line"
          />
        </div>
      </div>
    </div>
  );
};

export default LandingIntroduction;

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from './BackgroundImage';

const LandingClient = props => {
  const data = useStaticQuery(graphql`
    query {
      logo51: file(relativePath: { eq: "logo-51-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logo37: file(relativePath: { eq: "logo-37-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logo39: file(relativePath: { eq: "logo-39-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logo38: file(relativePath: { eq: "logo-38.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logo40: file(relativePath: { eq: "logo-40-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logo42: file(relativePath: { eq: "logo-42-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      visa: file(relativePath: { eq: "visa.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      kristal: file(relativePath: { eq: "kristal.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bob: file(relativePath: { eq: "bob-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      prudential: file(relativePath: { eq: "prudential.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      moneytap: file(relativePath: { eq: "moneytap-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      instarem: file(relativePath: { eq: "instarem.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      axisbank: file(relativePath: { eq: "axisbank.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tatacapital: file(relativePath: { eq: "tatacapital-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <div className="landing-client">
      <p>Trusted by global financial institutions</p>
      <div className="landing-client-logos">
        <BackgroundImage title="Prudential" data={data.prudential} className="brands__img brand__prudential" />
        <BackgroundImage title="NTUC Income" data={data.logo42} className="brands__img brand__ntuc" />
        <BackgroundImage title="InstaReM" data={data.instarem} className="brands__img brand__instarem" />
        <BackgroundImage title="BOB Financial" data={data.bob} className="brands__img" />
        <BackgroundImage title="MoneyTAP" data={data.moneytap} className="brands__img" />
        <BackgroundImage title="Kristal" data={data.kristal} className="brands__img" />
        <BackgroundImage title="TATA Capital" data={data.tatacapital} className="brands__img" />
        <BackgroundImage title="Kotak Mahindra Bank" data={data.logo38} className="brands__img" />
        <BackgroundImage title="IndusInd Bank" data={data.logo39} className="brands__img" />
        <BackgroundImage title="HDFC Securities" data={data.logo40} className="brands__img" />
        <BackgroundImage title="Axis Bank" data={data.axisbank} className="brands__img" />
      </div>
    </div>
  );
};

export default LandingClient;

import React from 'react';
import HeadTags from '../components/HeadTags';
import { RevealGlobalStyles } from 'react-genie';
import Layout from '../components/layout';

//! Components
import LandingBannerWrapper from '../components/LandingBannerWrapper';
import LandingClient from '../components/LandingClient';
import LandingIntroduction from '../components/LandingIntroduction';
import LandingIntroductionBenefitsWrapper from '../components/LandingIntroductionBenefitsWrapper';
import LandingExplanation from '../components/LandingExplanation';
import LandingBusinessBenefits from '../components/LandingBusinessBenefits';
import CTA from '../components/CTA';

//! CSS
import '../styles/style.css';
import '../styles/new.css';
import '../styles/revamp.css';
import '../styles/legal.css';

const IndexPage = () => (
  <Layout>
    <RevealGlobalStyles />
    <HeadTags
      title={`ChatBots | Customer Service Conversational AI Chatbots `}
      content={{
        type: 'website',
        url: '',
        description:
          'Automate your customer support and engagement with Conversational AI chatbots, used by leading banks, insurance companies and fintechs globally. No technical skills required.'
      }}
    />
    <div className="homepage" id="homepage">
      <LandingBannerWrapper
        title={'Supercharge your <br /> customer support'}
        subtitle={'Automate, engage, reduce cost with next-gen virtual bots'}
        image={true}
        page={'index'}
      />
      <LandingClient />
      <LandingIntroduction />
      <LandingIntroductionBenefitsWrapper />
      <LandingExplanation />
      <LandingBusinessBenefits />
      <CTA />
    </div>
  </Layout>
);

export default IndexPage;
